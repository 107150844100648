<template>
    <maintenance-page
        page-title="Property"
        page-icon="mdi-office-building"
        collection-name="property"
        @save="propertyUpdated"
    >
        <template v-slot:after-form>
            <PropertyAreaList
                v-if="propertyId"
                :property-id="propertyId"
                :base-url="baseUrl"
                :done-url="doneUrl"
                :key="JSON.stringify(property)"
            />
        </template>
    </maintenance-page>
</template>

<script>
import MaintenancePage from '../../../components/pages/Maintenance';

import PropertyAreaList from './PropertyAreaList';

export default {
    name: 'PropertyDetail',
    components: { MaintenancePage, PropertyAreaList },
    data: () => ({
        propertyId: null,
        property: '',
    }),
    methods: {
        init() {
            this.propertyId = this.$route.params.id;
        },
        propertyUpdated(val) {
            this.property = val;
        },
    },
    computed: {
        baseUrl() {
            return `property/${this.propertyId}/propertyarea`;
        },
        doneUrl() {
            return `property/${this.propertyId}`;
        },
    },
    watch: {
        '$route.params.id'(to, from) {
            console.log('propertydetail watched', { to });
            // Code
        },
    },
    updated() {
        this.init();
        console.log('updated propertyDetail', this.propertyId);
    },
    mounted() {
        this.init();
        console.log('mounted propertyDetail', this.propertyId);
    },
};
</script>
